import React from "react"
import {graphql} from "gatsby"
import {FormattedMessage, injectIntl, Link, useIntl} from "gatsby-plugin-react-intl"
import Parser from "html-react-parser"

import Layout from "../../components/layout"
import ProjectsNavbar from "../../components/projectsNavbar";

const SoftwarePage = ({data}) => {
    const intl = useIntl()
    const lang = intl.locale
    var posts = ""
    if (lang === "ru") {
        posts = data.ru_projects.edges;
    } else if (lang === "en") {
        posts = data.en_projects.edges;
    }
    return (
        <Layout>
            <ProjectsNavbar project_type="education"/>

            <h1 style={{marginTop: `20px`}}><FormattedMessage id="education.title"/></h1>
            <p style={{marginBottom: `5px`}}><FormattedMessage id="education.description"/></p>

            <ul>
                {posts.map(({node}) => {
                    const link = node.frontmatter.shortTitle;
                    const id = node.frontmatter.id;
                    return (
                        <li><Link to={`/projects/education#${id}`}>{link}</Link></li>
                    )
                })}
            </ul>

            {posts.map(({node}) => {
                const title = node.frontmatter.title;
                const id = node.frontmatter.id;
                const courseName = node.frontmatter.courseName;
                return (
                    <div className="row" id={id}>
                        <div className="col-lg-12">
                            <hr/>
                            <h2 id={id}>{title}</h2>
                            <div>{Parser(node.html)}</div>
                            <div className="card" style={{backgroundColor: `#F5F5F5`}}>
                                <div className="card-body" style={{padding: `10px`}}>
                                    <p style={{textAlign: `center`, margin: `5px`}}>
                                        <FormattedMessage id="education.course1"/><Link
                                        to={`/pages/${courseName}`}><FormattedMessage id="education.course2"/></Link>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })}
        </Layout>
    )
}

export default injectIntl(SoftwarePage)

export const query = graphql`
    query{
        ru_projects: allMarkdownRemark(filter: {frontmatter: {content_type: {eq: "project"}, project_type: {eq: "education"}, published: {ne: false}, language: {eq: "ru"}}},
            sort: {fields: [frontmatter___order], order: ASC}){
            edges{
                node{
                    html
                    frontmatter{
                        shortTitle
                        title
                        id
                        courseName
                    }
                }
            }}

        en_projects: allMarkdownRemark(filter: {frontmatter: {content_type: {eq: "project"}, project_type: {eq: "education"}, published: {ne: false}, language: {eq: "en"}}},
            sort: {fields: [frontmatter___order], order: ASC}){
            edges{
                node{
                    html
                    frontmatter{
                        shortTitle
                        title
                        id
                        courseName
                    }
                }
            }}
    }
`